import $ from "jquery";
import AOS from "aos";
import "@accessible360/accessible-slick";
import "@fancyapps/fancybox";

const spd = 300; // default speed
const AOSOptions = {
  duration: spd * 3,
  offset: 0,
  once: true,
};

// Navigation
function addListenersToNav() {
  if ($(window).width() <= 1280) {
    // Unbind
    $(".js-menu-toggle").unbind("click");

    // Rebind
    $(".js-menu-toggle").on("click", function (e) {
      $(this).toggleClass("active");
      $(this).next().toggleClass("active");
      $("html, body").toggleClass("no-scroll");
      e.preventDefault();
    });
  }
}

addListenersToNav();

$(window).resize(() => {
  addListenersToNav();
});

$(window).scroll(function () {
  const headerElement = $(".js-header");
  const navElement = $(".js-navigation");
  let navOffset = 20;

  if ($("body.admin-bar").length) {
    navOffset = 32;
  }

  if ($(this).scrollTop() >= headerElement.outerHeight() - navOffset) {
    navElement.addClass("sticky");
  } else {
    navElement.removeClass("sticky");
  }
});

// Don't use AOS on some mobile elements
if (window.innerWidth < 980) {
  unloadAOS(".alert-bar");
  unloadAOS(".navigation");
  unloadAOS(".header__socials");
  unloadAOS(".header__logo");
  unloadAOS(".header__contacts");

  setTimeout(() => {
    AOS.init(AOSOptions);
  }, spd * 2);
} else {
  AOS.init(AOSOptions);
}

// Reset AOS once animated
function unloadAOS(e) {
  $(e).removeClass("aos-init aos-animate").removeAttr("data-aos");
}

setTimeout(() => {
  unloadAOS(".navigation");
  unloadAOS(".header__logo");
}, 1050);

// Apply body class if alert is visible
if ($("#alert-message").length) {
  const alertHeight = $("#alert-message").outerHeight();

  $(".footer").addClass("footer--has-alert").css("padding-bottom", alertHeight);
}

// Accessible Slick
$(".js-slider").each(function () {
  let autoplay = false;
  let variableWidth = true;
  let centerPadding = "120px";

  if ($(this).data("autoplay")) {
    autoplay = true;
  }

  if ($(this).data("fixed-width")) {
    variableWidth = false;
  }

  if ($(this).data("no-center-padding")) {
    centerPadding = "";
  }

  $(this).slick({
    arrows: false,
    autoplay,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: "20px",
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerPadding: "40px",
        },
      },
      {
        breakpoint: 720,
        settings: {
          centerPadding: "80px",
        },
      },
      {
        breakpoint: 1280,
        settings: {
          arrows: true,
          centerPadding,
          variableWidth,
        },
      },
    ],
    slidesToShow: 1,
    speed: spd * 3,
  });
});

// Trigger brochure download on CF7 submit
const cf7form = document.querySelector(".wpcf7");
const cf7download = document.getElementById(
  "download",
) as HTMLInputElement | null;
const doDownload = parseInt(cf7download?.value, 10);

if (cf7form != null) {
  const downloadUrl = document.getElementById("download_url").innerText;

  cf7form.addEventListener(
    "wpcf7mailsent",
    () => {
      if (cf7download != null && doDownload === 1 && downloadUrl != null) {
        setTimeout(() => {
          window.open(downloadUrl, "_blank");
        }, 3000);
      }
    },
    false,
  );
}

// Mobile heading updates for text & image sections
$(".section--text-and-image").each(function () {
  const heading = $(this)
    .find(".section__column--text :header:first-child")
    .addClass("h--desktop")
    .clone()
    .addClass("h--mobile")
    .removeClass("h--desktop");
  $(this).find(".section__column--image").prepend(heading);
});

// "Read more" button
$(".js-read-more").parent().next().hide();
$(".js-read-more").on("click", function () {
  $(this).parent().slideUp(spd);
  $(this).parent().next().slideDown(spd);
});

// Display a modal for any links with the class "js-modal"
if (!("ontouchstart" in document.documentElement)) {
  $(".js-modal-trigger").on("click", function (e) {
    e.preventDefault();
    $(this).next().addClass("active");
    $("html, body").addClass("no-scroll");
  });
}

// Close the modal when clicking the close button
$(".js-modal, .js-modal-close").on("click", function () {
  $(this).closest(".modal").removeClass("active");
  $("html, body").removeClass("no-scroll");
});
